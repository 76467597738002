import React from "react";
import * as config from "~/config";

export enum DragMode {
  None = "none",
  Dragging = "dragging",
}

export interface UploadObject {
  name: string;
  blobUrl: string;
  uploadedUrl?: string;
  errorMessages: string[];
  isUploading: boolean;
}

const SUPPORTED_IMAGE_EXT = [
  "image/jpg",
  "image/jpeg",
  "image/png",
  "image/gif",
  "image/webp",
];

export function useFileUploads({ maxImageSize } = {}) {
  const [uploads, setUploads] = React.useState<UploadObject[]>([]);
  const [dragMode, setDragMode] = React.useState<DragMode>(DragMode.None);
  const [errorMessages, setErrorMessages] = React.useState<string[]>([]);
  const imageSizeLimit = maxImageSize ?? config.maxImageSize;

  const isUploading = React.useMemo(() => {
    return uploads.some((upload) => upload.isUploading);
  }, [uploads]);

  const validateFiles = (files: FileList) => {
    let imagesCount = 0;
    let videoCount = 0;
    const errors: string[] = [];
    for (const file of files) {
      if (!file) return;

      if (file.type.includes("image/")) {
        if (!SUPPORTED_IMAGE_EXT.includes(file.type)) {
          errors.push(`Invalid file type: ${file.type}`);
          break;
        }
        imagesCount++;
        if (file.size > imageSizeLimit) {
          errors.push("Image too large");
          break;
        }
      }
      // TODO: check for video formats
      if (file.type.startsWith("video/")) {
        videoCount++;
        if (file.size > config.maxVideoSize) {
          errors.push("Video too large");
          break;
        }
      }
    }
    if (imagesCount > 4) {
      errors.push("Too many images");
    }
    if (videoCount > 1) {
      errors.push("Too many videos");
    }
    if (imagesCount + videoCount === 0) {
      errors.push("No files");
    }
    if (imagesCount + videoCount > 4) {
      errors.push("Too many files");
    }

    return errors;
  };

  const validateImageFiles = (files: FileList) => {
    const errors: string[] = [];
    for (const file of files) {
      if (!file) return;

      if (!SUPPORTED_IMAGE_EXT.includes(file.type)) {
        errors.push(`Invalid file type: ${file.type}`);
      }

      if (file.size > imageSizeLimit) {
        errors.push("Image too large");
        break;
      }
    }

    return errors;
  };

  const uploadImage = async (file: File, signal: AbortSignal) => {
    const response = await fetch("/api/cloudflare-images", {
      method: "POST",
    });
    const cfImage = await response.json();
    const formData = new FormData();
    formData.append("file", file);
    const uploadResponse = await fetch(cfImage.uploadUrl, {
      signal,
      method: "POST",
      body: formData,
    });
    const uploaded = await uploadResponse.json();
    if (uploaded?.success) {
      const uploadedUrl = uploaded.result.variants[0].replace(
        /\/[a-z]*$/,
        "/public",
      );
      return uploadedUrl;
    }
    if (uploaded?.errors?.length) {
      console.error(uploaded.errors[0].message);
      throw new Error(
        "The image is invalid. Please try uploading a different image.",
      );
    }
  };

  const uploadVideo = async (file: File, signal: AbortSignal) => {
    const response = await fetch("/api/cloudflare-videos", {
      method: "POST",
    });
    const cfVideo = await response.json();
    const formData = new FormData();
    formData.append("file", file);
    const uploadResponse = await fetch(cfVideo.uploadUrl, {
      signal,
      method: "POST",
      body: formData,
    });
    if (uploadResponse.status !== 200) {
      return "";
    }
    const uploadedUrl = cfVideo.videoUrl;
    return uploadedUrl;
  };

  const processFile = async (file: File) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const blobUrl = reader.result as string;
      const newUpload: UploadObject = {
        name: file.name,
        blobUrl,
        errorMessages: [],
        isUploading: true,
      };
      setUploads((prevUploads) => [...prevUploads, newUpload]);
    };

    let uploadedUrl = "";
    const errorMessages: string[] = [];
    try {
      const controller = new AbortController();
      const { signal } = controller;
      if (file.type.startsWith("image/")) {
        uploadedUrl = await uploadImage(file, signal);
      } else if (file.type.startsWith("video/")) {
        uploadedUrl = await uploadVideo(file, signal);
      } else {
        throw Error("unsupported file format");
      }
    } catch (error) {
      console.error(error);
      errorMessages.push((error as Error).message);
      setErrorMessages(errorMessages);
    } finally {
      updateUpload(file.name, uploadedUrl, errorMessages);
    }
  };

  const updateUpload = (fileName: string, url: string, errors: string[]) => {
    setUploads((prevUploads) =>
      prevUploads.map((upload) =>
        upload.name === fileName
          ? {
              ...upload,
              uploadedUrl: url,
              errorMessages: errors,
              isUploading: false,
            }
          : upload,
      ),
    );
  };

  const processUrl = async (url: string, name: string) => {
    const response = await fetch(url);
    const blob = await response.blob();
    const file = new File([blob], name, { type: blob.type });
    processFile(file);
  };

  const removeUpload = (index: number) => {
    setUploads((uploads) =>
      uploads.filter((_, uploadIndex) => uploadIndex !== index),
    );
  };

  return {
    uploads,
    isUploading,
    dragMode,
    errorMessages,
    setUploads,
    setDragMode,
    setErrorMessages,
    processFile,
    validateFiles,
    validateImageFiles,
    processUrl,
    removeUpload,
  };
}
